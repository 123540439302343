<template>
    <div class="dynamic-page">
        <transition
            mode="out-in"
            name="page-gate"
        >
            <age-gate-section
                v-if="page.attributes.age_gate && !(ageApproved || ageConfirmed)"
                @age-approved="confirmAge"
                key="age-gate"
            />
            <div
                v-else
                key="default"
            >
                <page-module-builder
                    :components="page.attributes.components"
                />
            </div>
        </transition>
    </div>
</template>

<script>
    import PageModuleBuilder from '../../page-modules/PageModuleBuilder/PageModuleBuilder';
    import generatePageHead from '../../../lib/generate-page-head';
    import { runPageModulesAsyncData } from '../../../lib/page-modules';
    import loading from '../../../lib/loading';
    import { ITSU_COOKIE_AGE_NAME } from '../../../plugins/constants';
    import AgeGateSection from '../../organisms/AgeGateSection/AgeGateSection.vue';

    export default {
        name: 'dynamic-page',

        components: { PageModuleBuilder, AgeGateSection },

        head() {
            return generatePageHead(this, this.page);
        },

        async asyncData(context) {
            try {
                const { $strapi, route, query, store } = context;

                let { path } = route;

                const locale = store.state.locales.locales.find(({ code }) => code === route.path.split('/')[1] ?? 'en')?.code;

                if (locale) {
                    path = path.replace(`/${ locale }`, '');
                }

                loading().start();

                const page = await $strapi.fetchDynamicPage(path, {
                    previewId: query.preview,
                    locale,
                });

                if (!page) {
                    loading().fail();

                    return context.error({
                        statusCode: 404,
                    });
                }

                const locales = page
                    .attributes
                    .localizations
                    .data
                    .map(({ attributes }) => attributes.locale);

                context.store.commit('locales/setPageLocales', [
                    ...new Set([
                        page.attributes.locale,
                        ...locales,
                    ]),
                ]);

                context.store.commit('locales/setLocale', page.attributes.locale || 'en');

                (page.attributes.components || []).forEach((component) => {
                    component.__page = {
                        id: page.id,
                        published_at: page.attributes?.publishedAt,
                    };
                });

                await runPageModulesAsyncData(page, context);

                loading().finish();

                return {
                    page,
                };
            } catch (e) {
                loading().finish();

                //eslint-disable-next-line no-console
                console.log(e);

                context.error({
                    statusCode: 500,
                });
            }
        },

        data() {
            return {
                ageConfirmed: Boolean(this.$cookies.get(ITSU_COOKIE_AGE_NAME)),
            };
        },

        methods: {
            /**
             * Update ageConfirmed (so we don't have to refresh the page).
             */
            confirmAge() {
                this.ageConfirmed = true;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .page-gate-enter-active,
    .page-gate-leave-active {
        transition: all 0.5s ease-in-out;
    }

    .page-gate-enter-from,
    .page-gate-leave-to {
        opacity: 0;
    }

    .page-gate-leave-to {
        transform: translateY(10px);
    }
</style>

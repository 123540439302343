<template>
    <section class="age-gate-section">
        <b-container>
            <mobile-gutter>
                <div class="content">
                    <h1
                        class="title h3"
                        v-test:title
                    >
                        are you over <span class="circled">18</span>?
                    </h1>
                    <p class="why-text">
                        <span class="highlighted">[</span>we have to ask<span class="highlighted">]</span>
                    </p>
                    <div class="actions">
                        <b-button
                            @click="setAgeGate"
                            variant="primary"
                            size="lg"
                            v-test:btnConfirmAge
                        >
                            yes
                        </b-button>
                        <b-button to="/" variant="primary" size="lg">no</b-button>
                    </div>
                </div>
            </mobile-gutter>
        </b-container>
    </section>
</template>

<script>
    import { ITSU_COOKIE_AGE_NAME } from '../../../plugins/constants';

    export default {
        name: 'age-gate-section',

        methods: {
            /**
             * Set a cookie to know if user is over 18.
             */
            setAgeGate() {
                this.$cookies.set(ITSU_COOKIE_AGE_NAME, true);
                this.$emit('age-approved');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .age-gate-section {
        background-color: secondary-palette(7);
        padding: 60px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 120px 0;
        }
    }

    .title {
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile-gutter {
        @include media-breakpoint-up(sm) {
            padding: 0;
        }
    }

    .circled {
        outline: 5px solid $red;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        width: rem(32px);
        height: rem(32px);
        margin-left: 1ch;
        margin-right: 1ch;
        font-size: rem(24px);
        font-family: san-serif;
    }

    .why-text {
        font-size: rem(24px);
        line-height: rem(32px);
    }

    .highlighted {
        color: primary-palette(1);
    }

    .actions {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-top: 50px
    }
</style>
